const ProjectsData = [
  {
    id: 1,
    project: 'cube',
    category: 'design',
    en: {
      title: 'Cube cabinet',
      type: 'furniture',
      client: 'private',
      status: 'sold',
      content: `The main assignment for the design of the cube cabinet was to create a spatial element for the interior. To create a sculpture rather than a functional object.
      
      To main idea was to make a composition of a picture frame within a picture frame. The thin steel structure is the larger frame surrounding the other elements. The steel cube hanging in this structure is the smaller frame which is like a painting. Only the content of this painting isn’t static but can be filled in by the owner.
      
      The other elements like the horizontal wooden shelf, the drawer unit and the plant, are placed in the larger frame to create a balanced composition.`,
    },
    nl: {
      title: 'Cube cabinet',
      type: 'meubels',
      client: 'particulier',
      status: 'verkocht',
      content: `....`,
    },
  },
  {
    id: 2,
    project: 'fuchsia',
    category: 'arch',
    en: {
      title: 'Fuchsiastraat',
      type: 'residential extension',
      client: 'private',
      status: 'completed',
      content: `In the Fuchsiastraat in The Haque a new roof extension adds an additional floor to an apartment. Daylight is the main theme in this new extension by adding a large skylight and a void in the center of the house. Light welcomes you in the corridor where you enter the house and the void makes it possible to create a visual connection between the two floors. The living room and the kitchen are placed in the new floor together with a large roof terrace.`,
    },
    nl: {
      title: 'Fuchsiastraat',
      type: 'dakopbouw, uitbreiding',
      client: 'particulier',
      status: 'voltooid',
      content: `....`,
    },
  },
  {
    id: 3,
    project: 'vandijk',
    category: 'graphic',
    en: {
      title: 'Van Dijk Noten',
      type: 'visual identity',
      client: 'Van Dijk Noten',
      status: 'completed',
      content: `For Van Dijk Noten, we created a new visual identity. Van Dijk Noten is a specialty shop which sells roasted nuts, coffee and thee and dried fruits in Amersfoort. The nut, the coffee bean, and the tea leaf are all represented in the new logo. The clear and minimalistic visual identity incorporates all products, prints and marketing materials.`,
    },
    nl: {
      title: 'Van Dijk',
      type: 'visual identity',
      client: 'Van Dijk Noten',
      status: 'voltooid',
      content: `....`,
    },
  },
  {
    id: 4,
    project: 'dorfstrasse',
    category: 'arch',
    en: {
      title: 'Dorfstrasse',
      type: 'renovation',
      client: 'private',
      status: 'completed',
      content: `In the farmhouse on the Dorfstrasse in ? two renovations are completed.
      
      The first renovation is to guide light into the central corridor of the house. This has been done by adding daylight tubes in the roof and creating a void in the upper floor. This highlights the painting in the middle of the corridor.
      
      The second renovation was to transform a former workshop to a living area. This space is divided in three different zones, all cladded with their own unique materials, while keeping the structure visible.`,
    },
    nl: {
      title: 'Dorfstrasse',
      type: 'renovatie',
      client: 'private',
      status: 'voltooid',
      content: `....`,
    },
  },
  {
    id: 5,
    project: 'tripod',
    category: 'design',
    en: {
      title: 'Tripod bbq',
      type: 'outdoor furniture',
      client: '-',
      status: 'sold',
      content: `The tripod bbq consists of basic geometrical forms. The large triangle is like a frame to the other parts. The round pan is the heart of the composition. This is underlined by the huge scale below which grounds the whole structure.`,
    },
    nl: {
      title: 'Tripod bbq',
      type: 'outdoor furniture',
      client: '-',
      status: 'sold',
      content: `....`,
    },
  },
];

export default ProjectsData;
