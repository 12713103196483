import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__contact footer__contact-info'>
        get in touch
        <FontAwesomeIcon
          className='footer__icon footer__icon-down'
          icon={faArrowDown}
          size='2xs'
        />
        <FontAwesomeIcon
          className='footer__icon footer__icon-right'
          icon={faArrowRight}
          size='2xs'
        />
      </div>
      <div className='footer__wrapper'>
        <div className='footer__contact footer__contact-mail'>
          <a href='mailto:info@aabel.nl'>info@aabel.nl</a>
        </div>
        <div className='footer__contact footer__contact-phone'>
          <a href='tel:+31619005234'>tel: +31 619005234</a>
        </div>
        <div className='footer__social'>
          <a href='https://nl.pinterest.com/studioaabel/'>
            <FontAwesomeIcon
              className='footer__socialicon'
              icon={faPinterest}
            />
          </a>
          <a href='https://www.instagram.com/aalbersbellmann/'>
            <FontAwesomeIcon
              className='footer__socialicon'
              icon={faInstagram}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
